<template>
  <div class="">
    <!-- 解除绑定 -->
    <el-dialog
      :title="$t('unbind.unbind')"
      center
      class="unbind"
      :visible.sync="dialogFormVisible"
      @close="handleClose"
    >
      <el-table
        :data="gridData"
        ref="table"
        style="text-align: center"
        height="400px"
        :empty-text="$t('consume.noData')"
      >
        <el-table-column property="username" :label="$t('unbind.account')">
        </el-table-column>
        <el-table-column property="account" :label="$t('unbind.source')"> </el-table-column>
        <el-table-column fixed="right" :label="$t('unbind.operate')">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, gridData)"
              type="text"
              size="small"
            >
              {{$t('unbind.unbind')}}
            </el-button>
            <!-- <div v-if="!gridData[scope.$index].primary_account">主账号</div> -->
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      vipInfo: [],
      gridData: [],
    };
  },
  computed: {
    content(){
      return this.$t('unbind.sendCode')
    },
    levelCountry() {
      this.$store.state.country = localStorage.getItem("payCountry") || "VN";
      return this.$store.state.country;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    //解除绑定
    handleUnBind() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
      let params = {
        country:this.levelCountry
      };
      this.$http.get("/center/userBindInfo", {params}).then((res) => {
        if (res.data.code === 200) {
          let account = [
            "",
            "Email",
            "Chơi ngay",
            "FB",
            "Apple",
            "Huawei",
            "Mobile",
          ];
          res.data.data.forEach((item) => {
            item.account = account[item.account_type];
          });
          this.gridData = res.data.data;
          this.dialogFormVisible = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.dialogFormVisible = false;
      this.$parent.dialogFormVisible = true;
    },
    deleteRow(index, rows) {
      this.$confirm(
        this.$t('unbind.unbindAccount'),
        this.$t('unbind.cancelUnbind'),
        {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            username: rows[index].username,
            country:this.levelCountry
          };
          this.$http.get("/center/unbind", { params }).then((res) => {
            if (res.data.code === 200) {
              console.log(rows[index]);
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: this.$t('unbind.unbindSuccess'),
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t('unbind.cancelUnbindSuccess'),
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/ .cell {
  text-align: center;
}
/deep/ .el-table__fixed,
/deep/ .el-table__fixed-right {
  height: 100% !important;
}
.vip {
  /deep/.el-dialog {
    width: 500px;
    margin-top: 30vh !important;
  }
  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  /deep/ .el-button {
    margin-left: 0px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .vip {
    /deep/.el-dialog {
      width: 90%;
    }
  }
}
</style>
