<template>
  <div class="page">
    <el-dialog
      :title="$t('meet.title')"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <!-- <div class="vip_level_nav">遇见福利</div> -->
        <div class="vip_level_dec">
          {{ $t("meet.dec1") }}
        </div>
        <div class="vip_level_dec vip_level_meet">
          {{ $t("meet.time") }}{{ add_time }}
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">{{ $t("meet.table1") }}</div>
            <div class="vip_level_tb">{{ $t("meet.table2") }}</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">7</div>
            <div class="vip_level_tb">
              {{
                $t("meet.tableText", {
                  coin: meet[$parent.levelCountry][0].left,
                })
              }}
            </div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">8</div>
            <div class="vip_level_tb">
              {{
                $t("meet.tableText", {
                  coin: meet[$parent.levelCountry][1].left,
                })
              }}
            </div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">9</div>
            <div class="vip_level_tb">
              {{
                $t("meet.tableText", {
                  coin: meet[$parent.levelCountry][2].left,
                })
              }}
            </div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">10</div>
            <div class="vip_level_tb">
              {{
                $t("meet.tableText", {
                  coin: meet[$parent.levelCountry][3].left,
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      add_time: "",
      meet: {
        VN: [
          { left: "180000VND" },
          { left: "360000VND" },
          { left: "720000VND" },
          { left: "1800000VND" },
        ],
        TH: [
          { left: "260THB" },
          { left: "520THB" },
          { left: "1040THB" },
          { left: "2600THB" },
        ],
        ID: [
          { left: "110000" },
          { left: "220000" },
          { left: "440000" },
          { left: "1100000" },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.$parent.dialogFormVisible = true;
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #409eff;
div {
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.vip_level_nav {
  margin: 6.4px 0 1.6px 0;
  padding: 0 32px;
  line-height: 32px;
  font-size: 16px;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 16px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_meet {
  font-weight: bold;
  margin-top: 8px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
.vip_level_tr .vip_level_tb:nth-child(2) {
  flex: 2;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
}
</style>