<template>
  <div class="">
    <el-dialog
      title="VIP"
      center
      class="vip"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <Swiper
          ref="mySwiper"
          class="mySwiper"
          :options="swiperOptions"
          @slideChangeTransitionStart="levelChange"
          @touchEnd="levelChange"
        >
          <swiper-slide
            v-for="(item, index) in levelList"
            :key="index"
            class="level_item"
          >
            <img :src="item.icon" alt="" />
          </swiper-slide>
        </Swiper>
        <div class="vip_title">
          VIP<span>{{ level }}</span>
        </div>
        <div class="vip_scrollbar">
          <div
            class="vip_scrollbar_val"
            :style="`width: ${needExpScrollbar}%`"
          ></div>
        </div>
        <div class="vip_exp_text">
          <span v-if="level2 == level && level < 10">
            {{ $t("VipList.need", { exp: needExp }) }}
          </span>
          <span v-else-if="needExp >= 0">
            EXP： {{ exp }}/{{ levelList[level].exp }}
          </span>
          <!-- 该等级已满级 -->
          <span v-else> {{ $t("VipList.superlative") }}</span>
        </div>
        <div class="vip_explain">
          <div class="vip_explain_nav">
            {{ $t("VipList.exclusivePrivileges") }}
            {{ levelList[level].lok.length }}/9
          </div>
          <div class="vip_explain_box">
            <div
              class="vip_explain_item"
              v-for="(item, index) in vipExplain"
              :key="index"
              @click="goVipPath(item)"
              :style="`display: ${(item.ref == 'nap' && levelCountry === 'TH') ? 'none' :''}`"
            >
              <div class="vip_explain_info">
                <img
                  class="vip_explain_img"
                  :class="!item.colorShow ? 'vip_explain_img_show' : ''"
                  :src="item.png"
                  alt=""
                />
                <div class="vip_explain_name">{{ item.name }}</div>
                <div
                  class="vip_explain_remark"
                  v-if="item.colorShow && item.remark"
                >
                  {{ item.remark }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vip_level_nav">
          {{ $t("VipList.growthLevel") }}
        </div>
        <img class="vip_level_img" :src="imgSrc[levelCountry]" alt="" />
        <div class="vip_level_dec">
          {{ $t("VipList.dec1", { num: exp_text[levelCountry] }) }}
        </div>
        <div class="vip_level_dec">
          {{ $t("VipList.dec2") }}
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">{{ $t("VipList.table1") }}</div>
            <div class="vip_level_tb">{{ $t("VipList.table2") }}</div>
            <div class="vip_level_tb">{{ $t("VipList.table3") }}</div>
          </div>
          <div
            class="vip_level_tr"
            v-for="(item, index) in this.exp_list[levelCountry]"
            :key="index"
          >
            <div class="vip_level_tb">{{ index + 1 }}</div>
            <div class="vip_level_tb">{{ item.exp }}</div>
            <div class="vip_level_tb">{{ item.keep }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <SignIn ref="signIn" />
    <vipGift ref="vipGift" />
    <vipCoupon ref="vipCoupon" />
    <Nap ref="nap" />
    <retrieve ref="retrieve" />
    <meet ref="meet" />
    <recovery ref="recovery" />
    <bind ref="bind" />
    <unbind ref="unbind" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import SignIn from "./page/signIn.vue";
import vipGift from "./page/vipGift.vue";
import vipCoupon from "./page/vipCoupon.vue";
import Nap from "./page/nap.vue";
import retrieve from "./page/retrieve.vue";
import meet from "./page/meet.vue";
import recovery from "./page/recovery.vue";
import bind from "./page/bind.vue";
import unbind from "./page/unbind.vue";
export default {
  name: "",
  mixins: [],
  components: {
    SignIn,
    vipGift,
    vipCoupon,
    Nap,
    retrieve,
    meet,
    recovery,
    Swiper,
    SwiperSlide,
    bind,
    unbind,
  },
  directives: {
    swiper: directive,
  },
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      bindDialogFormVisible: false,
      unbindDialogFormVisible: false,
      vipInfo: [],
      gridData: [],
      swiper: null,
      level: 0, // 当前等级
      level2: 0, //备用等级
      exp: 0, // 当前经验
      add_time: "", //相遇时间
      needExp: 0, // 需要升级的经验
      needExpScrollbar: 0, //经验条长度
      modules: null,
      swiperOptions: {
        loop: false,
        autoplay: false, // 自动轮播
        speed: 1000, // 轮播速度
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        slideToClickedSlide: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 15,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        },
        // Some Swiper option/callback...
      },
      content: this.$t("bind.sendCode"),
      exp_list: {
        VN: [
          { exp: 300, keep: 120 },
          { exp: 900, keep: 450 },
          { exp: 1800, keep: 990 },
          { exp: 3200, keep: 1760 },
          { exp: 6500, keep: 3575 },
          { exp: 16000, keep: 8000 },
          { exp: 35000, keep: 17500 },
          { exp: 70000, keep: 35000 },
          { exp: 200000, keep: 80000 },
          { exp: 600000, keep: 240000 },
        ],
        TH: [
          { exp: 450, keep: 180 },
          { exp: 1300, keep: 650 },
          { exp: 2600, keep: 1430 },
          { exp: 4700, keep: 2585 },
          { exp: 9600, keep: 5280 },
          { exp: 23700, keep: 11850 },
          { exp: 52000, keep: 26000 },
          { exp: 104000, keep: 52000 },
          { exp: 300000, keep: 120000 },
          { exp: 900000, keep: 360000 },
        ],
        ID: [
          { exp: 200, keep: 80 },
          { exp: 570, keep: 285 },
          { exp: 1100, keep: 605 },
          { exp: 2100, keep: 1155 },
          { exp: 4200, keep: 2310 },
          { exp: 10000, keep: 5000 },
          { exp: 22000, keep: 11000 },
          { exp: 45000, keep: 22500 },
          { exp: 128000, keep: 51200 },
          { exp: 384000, keep: 153600 },
        ],
      },
      imgSrc: {
        VN: require("@/assets/img/vip/level_VN.png"),
        TH: require("@/assets/img/vip/level_TH.png"),
        ID: require("@/assets/img/vip/level_ID.png"),
      },
      exp_text: {
        VN: "1000VND = 1EXP",
        TH: "1THB = 1EXP",
        ID: "1000IDR = 1EXP",
      },
    };
  },
  computed: {
    levelList() {
      return [
        // vip等级
        {
          level: 0,
          icon: require("@/assets/img/vip/v0.png"),
          exp: 0,
          lok: [0],
          tips2: "",
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 1,
          icon: require("@/assets/img/vip/v1.png"),
          exp: this.exp_list[this.levelCountry][0].exp,
          lok: [0, 1, 2],
          tips2: this.$t("VipList.receive"),
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 2,
          icon: require("@/assets/img/vip/v2.png"),
          exp: this.exp_list[this.levelCountry][1].exp,
          lok: [0, 1, 2],
          tips2: this.$t("VipList.receive"),
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 3,
          icon: require("@/assets/img/vip/v3.png"),
          exp: this.exp_list[this.levelCountry][2].exp,
          lok: [0, 1, 2],
          tips2: this.$t("VipList.receive"),
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 4,
          icon: require("@/assets/img/vip/v4.png"),
          exp: this.exp_list[this.levelCountry][3].exp,
          lok: [0, 1, 2, 3],
          tips2: this.$t("VipList.receive"),
          tips4: "",
          tips5: "",
          tips7: "",
        },
        {
          level: 5,
          icon: require("@/assets/img/vip/v5.png"),
          exp: this.exp_list[this.levelCountry][4].exp,
          lok: [0, 1, 2, 3, 4],
          tips2: this.$t("VipList.receive"),
          tips4: "1%",
          tips5: "",
          tips7: "",
        },
        {
          level: 6,
          icon: require("@/assets/img/vip/v6.png"),
          exp: this.exp_list[this.levelCountry][5].exp,
          lok: [0, 1, 2, 3, 4, 5],
          tips2: this.$t("VipList.receive"),
          tips4: "1%",
          tips5: "1" + this.$t("VipList.time"),
          tips7: "",
        },
        {
          level: 7,
          icon: require("@/assets/img/vip/v7.png"),
          exp: this.exp_list[this.levelCountry][6].exp,
          lok: [0, 1, 2, 3, 4, 5, 6, 7],
          tips2: this.$t("VipList.receive"),
          tips4: "1%",
          tips5: "1" + this.$t("VipList.time"),
          tips7: "",
        },
        {
          level: 8,
          icon: require("@/assets/img/vip/v8.png"),
          exp: this.exp_list[this.levelCountry][7].exp,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: this.$t("VipList.receive"),
          tips4: "2%",
          tips5: "1" + this.$t("VipList.time"),
          tips7: "1" + this.$t("VipList.time"),
        },
        {
          level: 9,
          icon: require("@/assets/img/vip/v9.png"),
          exp: this.exp_list[this.levelCountry][8].exp,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: this.$t("VipList.receive"),
          tips4: "2%",
          tips5: "1" + this.$t("VipList.time"),
          tips7: "2" + this.$t("VipList.time"),
        },
        {
          level: 10,
          icon: require("@/assets/img/vip/v10.png"),
          exp: this.exp_list[this.levelCountry][9].exp,
          lok: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          tips2: this.$t("VipList.receive"),
          tips4: "3%",
          tips5: "1" + this.$t("VipList.time"),
          tips7: "3" + this.$t("VipList.time"),
        },
      ];
    },
    vipExplain() {
      return [
        // vip 权益
        {
          name: this.$t("VipList.name1"),
          path: "vip/signin",
          svg: "qdjf",
          png: require("@/assets/img/vip/qdjf.png"),
          check: true,
          remark: "",
          colorShow: false,
          ref: "signIn",
        },
        {
          name: this.$t("VipList.name2"),
          path: "vip/vipgift",
          svg: "lb",
          png: require("@/assets/img/vip/lb.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "vipGift",
        },
        {
          name: this.$t("VipList.name3"),
          path: "vip/vipcoupon",
          svg: "yhq",
          png: require("@/assets/img/vip/yhq.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "vipCoupon",
        },
        {
          name: this.$t("VipList.name4"),
          path: "vip/bind",
          svg: "bind",
          png: require("@/assets/img/vip/bind.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "bind",
        },
        {
          name: this.$t("VipList.name5"),
          path: "vip/nap",
          svg: "czjl",
          png: require("@/assets/img/vip/czjl.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "nap",
        },
        {
          name: this.$t("VipList.name6"),
          path: "vip/retrieve",
          svg: "bdzh",
          png: require("@/assets/img/vip/bdzh.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "retrieve",
        },
        {
          name: this.$t("VipList.name7"),
          path: "vip/unbind",
          svg: "unbind",
          png: require("@/assets/img/vip/unbind.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "unbind",
        },
        {
          name: this.$t("VipList.name8"),
          path: "vip/meet",
          svg: "yjfl",
          png: require("@/assets/img/vip/yjfl.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "meet",
        },
        {
          name: this.$t("VipList.name9"),
          path: "vip/recovery",
          svg: "wczhf",
          png: require("@/assets/img/vip/wczhf.png"),
          check: false,
          remark: "",
          colorShow: false,
          ref: "recovery",
        },
      ];
    },
    levelCountry() {
      this.$store.state.country = localStorage.getItem("payCountry") || "VN";
      return this.$store.state.country;
    },
  },
  watch: {
    dialogFormVisible(newValue) {
      if (newValue) {
        this.resetColorShow();
        this.showScrollbar();
      }
    },
  },
  created() {
    // this.setScrollbar();
  },
  mounted() {
    // this.onSwiper();
  },
  methods: {
    handleClose() {
      this.dialogFormVisible = false;
    },
    setScrollbar() {
      this.levelList.forEach((item, index) => {
        if (this.exp >= item.exp) {
          item.level < 10 && item.level > 0
            ? (this.level = item.level + 1)
            : (this.level = item.level);
        }
      });
      this.showScrollbar();
    },
    showScrollbar() {
      this.levelList[this.level].lok.forEach((item) => {
        if (this.vipExplain[item]) {
          this.vipExplain[item].colorShow = true;
        }
      });
      if (this.level <= 10) {
        this.needExp =
          this.level == this.level2 && this.levelList[Number(this.level) + 1]
            ? this.levelList[Number(this.level) + 1].exp - this.exp
            : this.levelList[this.level].exp - this.exp;
        this.needExpScrollbar =
          this.level == this.level2 && this.levelList[Number(this.level) + 1]
            ? (this.exp / this.levelList[Number(this.level) + 1].exp).toFixed(
                2
              ) * 100
            : (this.exp / this.levelList[this.level].exp).toFixed(2) * 100;
      }
    },
    onSwiper() {
      this.swiper.slideTo(this.level, false);
    },
    resetColorShow() {
      //将每个icon置空
      this.levelList[10].lok.forEach((item) => {
        if (this.vipExplain[item]) {
          this.vipExplain[item].colorShow = false;
        }
      });
    },
    levelChange() {
      this.level = this.swiper.activeIndex;
      this.chooseRemark();
      this.resetColorShow();
      this.showScrollbar();
    },
    //获取Icon高亮
    goVipPath(item) {
      if (!item.colorShow) return;
      this.$refs[item.ref].dialogFormVisible = true;
      // this.dialogFormVisible = false;
      switch (item.ref) {
        case "meet":
          this.$refs[item.ref].add_time = this.add_time;
          break;
        case "vipGift":
          let serveInfo = this.$store.state.ServeInfo;
          this.$refs[item.ref].servers_roles.servers = serveInfo;
          this.$refs[item.ref].userInfo.level = this.level2;
          break;
        case "bind":
          this.$refs[item.ref].vipInfo = this.vipInfo;
          break;
        case "unbind":
          this.$refs[item.ref].handleUnBind();
          break;
        default:
          break;
      }
      // this.$router.push(item.path);
    },
    getSwiper() {
      this.$nextTick(() => {
        this.swiper = this.$refs.mySwiper.$swiper;
        this.swiper.slideTo(this.level, false);
        this.chooseRemark();
        this.resetColorShow();
        this.showScrollbar();
      });
    },
    //充值remark
    chooseRemark() {
      if (this.levelList[this.level].tips2) {
        this.vipExplain[1].remark = this.levelList[this.level].tips2;
      }
      if (this.levelList[this.level].tips4) {
        this.vipExplain[4].remark = this.levelList[this.level].tips4;
      }
      if (this.levelList[this.level].tips5) {
        this.vipExplain[5].remark = this.levelList[this.level].tips5;
      }
      if (this.levelList[this.level].tips7) {
        this.vipExplain[8].remark = this.levelList[this.level].tips7;
      }
    },
  },
};
</script>

<style scoped lang="scss">
$vip-color: #409eff;
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
div {
  white-space: pre-wrap;
  word-break: break-word;
}
.page_box {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mySwiper {
  width: 100%;
  min-height: 32px;
  height: 150px;
  overflow: hidden;
}
.level_item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4.8px;
  width: 50% !important;
  box-sizing: border-box;
}
.level_item img {
  width: 180px;
}
.vip_title {
  font-weight: bold;
  font-size: 24px;
  color: $vip-color;
}
.vip_scrollbar {
  margin: 2.4px 0;
  width: 75%;
  height: 12px;
  min-height: 12px;
  background-color: #e1e1e1;
  border-radius: 16px;
  overflow: hidden;
}
.vip_scrollbar_val {
  width: 0;
  height: 100%;
  background-color: $vip-color;
}
.vip_exp_text {
  font-size: 16px;
  font-weight: bold;
}
.vip_explain {
  margin-top: 3.2px;
  width: 95%;
  max-height: 330px;
  background-color: rgba(17, 136, 255, 0.1);
  border-radius: 10px;
}
.vip_explain_nav {
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #409eff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}
.vip_explain_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3.2px;
}
.vip_explain_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  width: 16.5%;
  cursor: pointer;
  position: relative;
}
.vip_explain_remark {
  position: absolute;
  top: -5px;
  right: -18px;
  width: 70px;
  text-align: center;
  border-radius: 14px 15px 15px 0;
  background-color: red;
  color: #fff;
}
.vip_explain_icon {
  padding: 2.4px;
  width: 50px;
  height: 50px;
  min-height: 50px;
  background-color: #f0d4a0;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.vip_explain_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
.vip_explain_img {
  width: 50%;
}
.vip_explain_img_show {
  filter: grayscale(100%);
}
.vip_explain_icon_show {
  background-color: #e1e1e1;
}
.vip_explain_icon svg {
  position: absolute;
  left: 15%;
  top: 15%;
  width: 40px;
  height: 40px;
}
.vip_explain_title {
  margin-top: 1.6px;
  font-size: 16px;
  color: #000;
}
.vip_explain_title_show {
  margin-top: 1.6px;
  font-size: 16px;
  color: #8d8d8d;
}
.vip_level_nav {
  margin: 32px auto 0;
  padding: 5px 0px;
  color: $vip-color;
  // border: 1px solid $vip-color;
  // border-radius: 30px;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  font-size: 24px;
}
.vip_level_img {
  margin: 4.8px 0;
  width: 95%;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 100%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 52px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
  /deep/.el-dialog__body {
    padding: 25px 15px 30px;
  }

  .vip_explain_item {
    padding: 8px 0;
    width: 25%;
  }
  .vip_explain_remark {
    top: -8px;
    right: -20px;
    width: 57px;
  }
  .level_item img {
    width: 115px;
  }
  .vip_level_tb {
    font-size: 12px;
  }
  .vip_explain_box {
    justify-content: flex-start;
    text-align: center;
  }
}
</style>
