<template>
  <div class="">
    <!-- 绑定 -->
    <el-dialog
      title="Bind"
      class="bind"
      center
      :visible.sync="dialogFormVisible"
      @close="handleBindClose('ruleForm')"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="email">
          <el-input
            name="email"
            v-model.trim="ruleForm.email"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            prefix-icon="icon iconfont icon-youjian"
            :placeholder="$t('bind.enterEmail')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model.trim="ruleForm.code"
            class="codeInput"
            maxlength="4"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            prefix-icon="icon iconfont icon-baohu1"
            :placeholder="$t('bind.enterCode')"
          >
            <el-button
              slot="append"
              @click="sendMsg"
              class="sendMsg"
              type="primary"
              :disabled="canClick"
            >
              {{ content }}
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            name="password"
            v-model.trim="ruleForm.password"
            type="password"
            maxlength="16"
            autocomplete="new-password"
            prefix-icon="icon iconfont icon-mima"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            :placeholder="$t('bind.enterPassword')"
            readonly
            onfocus="this.removeAttribute('readonly');"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            v-model.trim="ruleForm.checkPass"
            type="password"
            maxlength="16"
            autocomplete="new-password"
            prefix-icon="icon iconfont icon-mima"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            :placeholder="$t('bind.enterPasswordAgain')"
            readonly
            onfocus="this.removeAttribute('readonly');"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="button-contaniner">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >{{$t('unlock.confirm')}}</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">reset</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      vipInfo: [],
      totalTime: 60,
      canClick: false,
      ruleForm: {
        email: "",
        code: "",
        password: "",
        checkPass: "",
      },
      gridData: [],
      content:this.$t("bind.sendCode")
    };
  },
  computed: {
    rules() {
      let validateEmail = (rule, value, callback) => {
        let reg = new RegExp(
          /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/
        );
        if (value === "") {
          callback(new Error(this.$t("bind.enterEmail")));
        } else if (!reg.test(value)) {
          callback(new Error(this.$t("bind.enterTrueEmail")));
        } else {
          callback();
        }
      };
      let validatePass = (rule, value, callback) => {
        let reg = new RegExp(/^([a-zA-Z]|[a-zA-Z0-9_]|[0-9]){6,20}$/);
        if (value === "") {
          callback(new Error(this.$t("bind.enterPassword")));
        } else if (value.length < 6 || !reg.test(value)) {
          callback(new Error(this.$t("bind.enterNumPassword")));
        } else {
          if (this.ruleForm.checkPass !== "") {
            this.$refs.ruleForm.validateField("checkPass");
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(this.$t("bind.enterPasswordAgain")));
        } else if (value !== this.ruleForm.password) {
          callback(new Error(this.$t("bind.differentPassword")));
        } else {
          callback();
        }
      };
      return {
        email: [
          { required: true, validator: validateEmail, trigger: "change" },
        ],
        code: [
          {
            required: true,
            message: this.$t("bind.enterCode"),
            trigger: "change",
          },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "change" },
        ],
      };
    },
    levelCountry() {
      this.$store.state.country = localStorage.getItem("payCountry") || "VN";
      return this.$store.state.country;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      let { email, code, password } = this.ruleForm;
      let params = {
        email: email,
        code: code,
        password: password,
        country:this.levelCountry
      };
      this.$http.get("center/bind", { params }).then((res) => {
        if (res.data.code === 200) {
          this.dialogFormVisible = false;
          this.$store.state.showServe = false;
          this.$store.state.selectServe = false;
          this.$message.success(this.$t("bind.bindSuccess"));
          localStorage.removeItem("token");
          localStorage.removeItem("service");
          this.$router.push({
            path: "/login",
            query: { g: this.vipInfo.game_id },
          });
        } else if (res.data.code === 206) {
          this.$message.error(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }, 300),
    handleBindClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      if (this.$store.state.selectServe) {
        this.$parent.dialogFormVisible = true;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendMsg() {
      if (this.canClick) return;
      if (!this.ruleForm.email) {
        this.$message.error(this.$t("bind.enterEmail"));
        return;
      }
      let params = {
        email: this.ruleForm.email,
        country:this.levelCountry
      };
      this.$http.get("center/sendEmailCode", { params }).then((res) => {
        if (res.data.code === 200) {
          this.canClick = true;
          this.content = this.totalTime + "s";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.content = this.totalTime + "s";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.content = this.$t("Login.resend");
              this.totalTime = 60;
              this.canClick = false;
            }
          }, 1000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/ .cell {
  text-align: center;
}
/deep/ .el-table__fixed,
/deep/ .el-table__fixed-right {
  height: 100% !important;
}
.vip {
  /deep/.el-dialog {
    width: 500px;
    margin-top: 30vh !important;
  }
  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  /deep/ .el-button {
    margin-left: 0px;
    margin-bottom: 5px;
  }
}
.bind {
  /deep/ .icon,
  .iconfont {
    color: #409eff;
  }
  /deep/ .el-input-group__append {
    background: #409eff;
    color: #fff;
  }
  .button-contaniner {
    text-align: center;
    /deep/.el-form-item__content {
      margin-left: 0px !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .vip {
    /deep/.el-dialog {
      width: 90%;
    }
  }
}
</style>
