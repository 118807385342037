<template>
  <div class="">
    <el-dialog
      :title="$t('orderList.purchaseLog')"
      :width="$isMobile ? '90%' : ''"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      center
    >
      <div class="pointOrder_List">
        <div
          class="pointOrder_item"
          v-for="item in orderList"
          :key="item.platform_orderno"
        >
          <div class="pointOrder_cell">
            <div>No : {{ item.platform_orderno }}</div>
          </div>
          <div class="pointOrder_cell">
            <div>
              {{ $t("orderList.purchasePrice") }} :
              <span>{{ item.pay_amt }} {{item.pay_unit}}</span>
            </div>
          </div>
          <div class="pointOrder_cell" v-if="Number(item.free_coin) > 0">
            <div>
              {{ $t("orderList.giftAmount") }} :
              <span>{{ item.free_coin }} coin</span>
            </div>
          </div>
          <div class="pointOrder_cell">
            <div>
              {{ $t("orderList.createTime") }} :
              <span>{{ item.add_time }}</span>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">
          {{ $t("unlock.confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      orderList: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 获取滚动容器的引用
    // 初始化列表数据
  },
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },
    getData() {
      this.fetchData();
    },
    fetchData() {
      // 发送API请求，获取新的数据
      let payCountry = localStorage.getItem("payCountry") || "VN";
      let params = { country: payCountry };
      this.$http.get("/center/order", { params }).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}

.pointOrder_List {
  height: 500px;
  overflow: auto;
}
.pointOrder_item {
  padding: 6px 8px;
  border-bottom: 1px solid #eee;
  font-size: 18px;
}
.pointOrder_cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}
.pointOrder_cell div:nth-child(1) {
  flex: 1;
}
@media screen and (max-width: 1000px) {
  /deep/ .el-dialog--center .el-dialog__body {
    padding: 25px 10px 30px;
  }
  .pointOrder_List {
    height: 300px;
  }
  .pointOrder_item {
    font-size: 12px;
  }
}
</style>
