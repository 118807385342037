<template>
  <div class="page">
    <el-dialog
      :title="$t('vipCoupon.vipCoupon')"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <!-- <div class="vip_level_nav">VIP优惠券</div> -->
        <div class="vip_level_dec">
          {{ $t("vipCoupon.dec1") }}
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">{{ $t("vipCoupon.table1") }}</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.table2") }}</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">1</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText2") }}*1</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">2</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText2") }}*2</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">3</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText2") }}*3</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">4</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText3") }}*1</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">5</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText3") }}*2</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">6</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText3") }}*3</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">7</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText4") }}*1</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">8</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText4") }}*2</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">9</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText4") }}*3</div>
          </div>
          <div class="vip_level_tr">
            <div class="vip_level_tb">10</div>
            <div class="vip_level_tb">{{ $t("vipCoupon.tableText5") }}*1</div>
          </div>
        </div>
        <div class="vip_level_dec">
          <span class="vip_level_coupon">{{ $t("vipCoupon.dec2") }}</span>
          {{
            $t("vipCoupon.dec2Text", {
              money1: vipGift[levelCountry][0].money1,
              num1: vipGift[levelCountry][0].num1,
              money2: vipGift[levelCountry][0].money2,
              num2: vipGift[levelCountry][0].num2,
            })
          }}
        </div>
        <div class="vip_level_dec">
          <span class="vip_level_coupon">{{ $t("vipCoupon.dec3") }}</span>
          {{
            $t("vipCoupon.dec3Text", {
              money1: vipGift[levelCountry][1].money1,
              num1: vipGift[levelCountry][1].num1,
              money2: vipGift[levelCountry][1].money2,
              num2: vipGift[levelCountry][1].num2,
            })
          }}
        </div>
        <div class="vip_level_dec">
          <span class="vip_level_coupon">{{ $t("vipCoupon.dec4") }}</span>
          {{
            $t("vipCoupon.dec4Text", {
              money1: vipGift[levelCountry][2].money1,
              num1: vipGift[levelCountry][2].num1,
              money2: vipGift[levelCountry][2].money2,
              num2: vipGift[levelCountry][2].num2,
            })
          }}
        </div>
        <div class="vip_level_dec">
          <span class="vip_level_coupon">{{ $t("vipCoupon.dec5") }}</span
          >{{
            $t("vipCoupon.dec5Text", {
              money1: vipGift[levelCountry][3].money1,
              num1: vipGift[levelCountry][3].num1,
              money2: vipGift[levelCountry][3].money2,
              num2: vipGift[levelCountry][3].num2,
            })
          }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      vipGift: {
        VN: [
          { money1: "22000", num1: "4000", money2: "109000", num2: "22000" },
          { money1: "109000", num1: "24000", money2: "699000", num2: "210000" },
          {
            money1: "1099000",
            num1: "350000",
            money2: "2199000",
            num2: "800000",
          },
          {
            money1: "1099000",
            num1: "500000",
            money2: "2199000",
            num2: "1000000",
          },
        ],
        TH: [
          { money1: "32", num1: "6", money2: "160", num2: "32" },
          { money1: "160", num1: "35", money2: "1030", num2: "310" },
          { money1: "1630", num1: "510", money2: "3250", num2: "1180" },
          { money1: "1630", num1: "740", money2: "3250", num2: "1480" },
        ],
        ID: [
          { money1: "14000", num1: "2560", money2: "70000", num2: "14000" },
          { money1: "70000", num1: "15360", money2: "440000", num2: "134000" },
          {
            money1: "700000",
            num1: "224000",
            money2: "1400000",
            num2: "512000",
          },
          {
            money1: "700000",
            num1: "320000",
            money2: "1400000",
            num2: "640000",
          },
        ],
      },
    };
  },
  computed: {
    levelCountry() {
      this.$store.state.country = localStorage.getItem("payCountry") || "VN";
      return this.$store.state.country;
    },
  },
  methods: {
    handleClose() {
      this.$parent.dialogFormVisible = true;
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #409eff;
div {
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.vip_level_nav {
  padding: 5px 65px;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
  width: 100px;
  font-size: 18px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_coupon {
  font-weight: bold;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
}
</style>