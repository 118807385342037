<template>
  <div style="height: 100%">
    <el-dialog
      :title="$t('store.pointsStore')"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      style="height: 80%; margin: auto; transform: translateY(10%)"
      center
      :width="$isMobile ? '90%' : '60%'"
    >
      <div v-loading="loading">
        <div class="header">
          <div class="header-left">{{$t('store.integral')}} :{{ code }}</div>
          <div @click="openLog()" class="point_btn button_primary">
            {{$t('store.exchangeRecord')}}
          </div>
        </div>
        <div class="search">
          <el-select
            v-model="value"
            @change="handleSelect()"
            :no-data-text="$t('consume.noData')"
            :placeholder="$t('store.productTypes')"
          >
            <el-option
              v-for="item in selectList.data"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <div class="shop_list" v-loading="goodsLoading">
          <div
            @click="openShopDetail(item)"
            class="shop_list_item_bg"
            v-for="item in shopList"
            :key="item.id"
          >
            <div class="shop_list_item">
              <img class="shop_list_item_icon" :src="item.img" />
              <div class="shop_list_item_text">
                <div class="shop_list_item_name">
                  {{ item.name }}
                </div>
                <div class="shop_list_item_cell">
                  {{ item.point }} {{$t('store.integral')}}<span v-if="Number(item.amount)">
                    + {{ item.amount }}VND</span
                  >
                </div>
                <div class="shop_list_item_cell">
                  {{$t('store.RemainingNum')}} : {{ item.used }}/{{ item.total }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> Hủy </el-button>
      </div> -->
    </el-dialog>
    <Log ref="logs" />
    <GoodsDetail ref="GoodsDetail" :info="goodsDetails" />
  </div>
</template>

<script>
import Log from "./log.vue";
import GoodsDetail from "./goodsDetail";
export default {
  name: "",
  mixins: [],
  components: { Log, GoodsDetail },
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      code: 0,
      loading: true,
      goodsLoading: true,
      selectList: {},
      shopList: [],
      atmList: [],
      value: 0,
      goodsDetails: {},
      logDialog: false,
      GoodsDetailDialog: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },
    handleClose() {
      this.dialogFormVisible = false;
    },
    //获取选择框下拉接口
    getSelectData() {
      let params = {};
      this.$http.get("/point/goodsType", { params }).then((res) => {
        if (res.data.code === 200) {
          this.selectList = res.data;
          this.loading = false;
          this.goodsLoading = true;
          this.getInfoList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取信息列表
    getInfoList() {
      let params = {
        type: this.value,
      };
      this.$http.get("/point/goodsList", { params }).then((res) => {
        if (res.data.code === 200) {
          this.shopList = res.data.data.list;
          this.atmList = res.data.data.atm;
          this.goodsLoading = false;
          this.code = res.data.data.point
          // setTimeout(() => {
          //   this.goodsLoading = false;
          // }, 3000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSelect() {
      this.getInfoList();
    },
    openLog() {
      this.$refs.logs.dialogFormVisible = true;
      this.$refs.logs.logDialog = true;
      this.$refs.logs.getData();
    },
    openShopDetail(item) {
      this.$refs.GoodsDetail.GoodsDetailDialog = true;
      this.$refs.GoodsDetail.dialogFormVisible = true;
      item.code = this.code;
      item.show = true;
      item.btn = this.$t('store.Revise');
      if (item.pay_type === "2") {
        item.atmList = this.atmList;
      }
      if (Number(item.point) > Number(item.code)) {
        // 积分不足
        item.btn = this.$t('store.NotEnoughPoints');
        item.show = false;
      }
      if (
        Number(item.user_limit) >= Number(item.limit) &&
        Number(item.limit) != -1
      ) {
        // 今日兑换已上限
        item.btn = this.$t('store.exchangeLimit');
        item.show = false;
      }
      if (
        Number(item.daily_limit) >= Number(item.single_limit) &&
        Number(item.single_limit) != -1
      ) {
        // 今日商品不足

        item.btn = this.$t('store.NotEnoughGoods');
        item.show = false;
      }
      if (
        Number(item.user_month_limit) >= Number(item.month_limit) &&
        Number(item.month_limit) != -1
      ) {
        // 本月商品不足

        item.btn = this.$t('store.productExchangeLimit');
        item.show = false;
      }
      if (Number(item.used) >= Number(item.total)) {
        // 该商品已达兑换上限
        item.btn = this.$t('store.productExchangeLimit');
        item.show = false;
      }
      this.goodsDetails = item;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog__wrapper {
  height: 100% !important;
}
/deep/.el-dialog {
  border-radius: 32px !important;
  margin-top: 8vh !important;
}

/deep/.el-select {
  width: 80%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 24px;
  .header-left {
    color: #1188ff;
  }
  .point_btn {
    padding: 0px 14px;
    line-height: 38px;
    text-align: center;
    border-radius: 24px;
    cursor: pointer;
    margin-left: 30px;
  }
  .button_primary {
    background-image: linear-gradient(to right, #57ebfe, #1188ff);
    color: #fff;
    box-shadow: 0 0 0.05rem #57ebfe;
  }
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.shop_list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0.2rem 0.1rem;
  flex: 1;
  overflow-y: auto;
  height: 300px;
  .shop_list_item_bg {
    padding: 16px;
    width: 25%;
    height: auto;
    box-sizing: border-box;
    .shop_list_item {
      box-shadow: 0 0 16px #ddd;
      cursor: pointer;
      height: 100%;
      .shop_list_item_icon {
        width: 100%;
      }
      .shop_list_item_text {
        padding: 12px;
      }
    }
  }
}


@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    margin-top: 4vh !important;
  }
  /deep/.el-dialog--center .el-dialog__body{
    padding: 25px 10px 30px;
  }
  .header {
    font-size: 14px;
  }
  .shop_list {
    .shop_list_item_bg {
      width: 50%;
      padding: 8px;
    }
  }
}
@media screen and (max-width: 376px) {
  .shop_list {
    height: 200px !important;
  }
  .shop_list_item_text{
    font-size: 10px;
  }
}
</style>

