<template>
  <div class="page">
    <el-dialog
      :title="$t('signIn.title')"
      center
      class="vip"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="page_box">
        <!-- <div class="vip_level_nav">签到积分</div> -->
        <div class="vip_level_dec">
          {{ $t("signIn.dec") }}
        </div>
        <div class="vip_level_table">
          <div class="vip_level_tr">
            <div class="vip_level_tb">{{ $t("signIn.table1") }}</div>
            <div class="vip_level_tb">{{ $t("signIn.table2") }}</div>
          </div>
          <div
            class="vip_level_tr"
            v-for="(item, index) in signIn[levelCountry]"
            :key="index"
          >
            <div class="vip_level_tb">{{ item.left }}</div>
            <div class="vip_level_tb">{{ item.right }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      signIn: {
        VN: [
          { left: "0~3", right: "3" },
          { left: "4~5", right: "5" },
          { left: "6~7", right: "7" },
          { left: "8~9", right: "9" },
          { left: "10", right: "12" },
        ],
        TH: [
          { left: "0~3", right: "4" },
          { left: "4~5", right: "7" },
          { left: "6~7", right: "10" },
          { left: "8~9", right: "13" },
          { left: "10", right: "17" },
        ],
        ID: [
          { left: "0~3", right: "2" },
          { left: "4~5", right: "3" },
          { left: "6~7", right: "5" },
          { left: "8~9", right: "6" },
          { left: "10", right: "8" },
        ],
      },
    };
  },
  computed: {
    levelCountry() {
      this.$store.state.country = localStorage.getItem("payCountry") || "VN";
      return this.$store.state.country;
    },
  },
  methods: {
    handleClose() {
      this.$parent.dialogFormVisible = true;
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #409eff;
div {
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.el-dialog {
  width: 700px;
  border-radius: 32px !important;
}
.page_box {
  padding: 0 3.52px 16px 3.52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.vip_level_nav {
  padding: 5px 65px;
  color: $vip-color;
  border: 1px solid $vip-color;
  border-radius: 30px;
  text-align: center;
  width: 100px;
  font-size: 18px;
}
.vip_level_img {
  margin: 4.8px 0;
}
.vip_level_dec {
  line-height: 32px;
  font-size: 16px;
}
.vip_level_table {
  margin-top: 4.8px;
  width: 95%;
  border-left: 0.0625rem solid #ddd;
  border-bottom: 0.0625rem solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: $vip-color;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  min-height: 32px;
  color: #fff;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
  font-size: 16px;
  border-top: 0.0625rem solid #ddd;
  border-right: 0.0625rem solid #ddd;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 95%;
  }
}
</style>